<template>
  <main class="mb-5">
    <section
      class="pages-path d-flex my-3 py-2 px-3 container bg-white box-shadow20 border-radius15 "
    >
      <svg
        class="ml-2"
        width="17"
        height="17"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
          stroke="#999"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        fill="currentColor"
        class="ml-2 mt-1 bi bi-chevron-double-left"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          fill="#a5a5a5"
        />
        <path
          fill-rule="evenodd"
          d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          fill="#a5a5a5"
        />
      </svg>
      <router-link tag="span" to="/" class="fontsize12 text-color-666"
        >خانه</router-link
      >
      <svg
        class="mx-2 mt-1"
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 19L8.5 12L15.5 5"
          stroke="#a5a5a5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <router-link tag="span" to="/blogs" class="fontsize11 text-color-a5"
        >لیست مطالب</router-link
      >
      <svg
        class="mx-2 mt-1"
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 19L8.5 12L15.5 5"
          stroke="#a5a5a5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span v-if="blog" class="fontsize11 text-color-a5 text-right">{{
        blog.title
      }}</span>
    </section>
    <hr class="w-100 container d-md-block d-none bg-color-fa" />
    <div class="weblog-detail-grid container text-right">
      <div class="welog-list-side d-lg-block d-none py-3">
        <CategoryList></CategoryList>

        <section
          class="advertisting1 bg-color-ea border-radius15 box-shaddow20 text-center mb-3"
        >
          <a href=""><img src="../../assets/images/ads.png" alt=""/></a>
        </section>
        <PopularBlogs></PopularBlogs>
      </div>
      <div class="weblog-list-main p-lg-3">
        <section
          class="weblog-list-details  border-radius15 bg-white box-shaddow20 p-3"
        >
          <div class=" weblog-detail-title pr-3">
            <h1 class="text-color-444 weight-bold fontsize20" v-if="blog">
              {{ blog.title }}
            </h1>
            <b-skeleton
              class="my-2"
              style="width: 70%"
              v-else-if="!blog"
            ></b-skeleton>
          </div>
          <div
            class="weblog-details d-flex flex-wrap justify-content-lg-between fontsize10 text-color-a5 my-3 pr-3"
          >
            <span class="ml-3 mb-1" v-if="blog">{{
              moment(blog.created_at).format("jDD jMMMM jYYYY")
            }}</span>
            <b-skeleton v-if="!blog" width="82px"></b-skeleton>
            <span class="ml-3 mb-1" v-if="blog"
              >توسط {{ blog.admin.name }}</span
            >
            <b-skeleton v-if="!blog" width="82px"></b-skeleton>
            <span class="ml-3 mb-1" v-if="blog"
              >{{ blog.views_count }} بازدید</span
            >
            <b-skeleton v-if="!blog" width="82px"></b-skeleton>
            <span class="ml-3 mb-1" v-if="blog">{{
              blog.comments.length === 0
                ? "بدون دیدگاه"
                : blog.comments.length + " دیدگاه"
            }}</span>
            <b-skeleton v-if="!blog" width="82px"></b-skeleton>
            <div v-if="blog && blog.category">
              <span class="text-color-666 ml-2">دسته بندی :</span>
              <router-link
                tag="span"
                :to="
                  '/blogs/category/' +
                    blog.category.id +
                    '/' +
                    blog.category.slug
                "
                class="px-2 bg-color-themeBlue rounded text-white cursor-pointer"
                >{{ blog.category.name }}</router-link
              >
            </div>
          </div>
          <div class="weblog-details-img w-100 border-radius15 mb-4">
            <img
              class="w-100 border-radius15"
              :class="{ 'cursor-wait': !blog, 'loading-blog-image': !blog }"
              :src="blog ? blog.image : '/assets/images/gray-bg.png  '"
              :alt="blog ? blog.title : ''"
            />
          </div>
          <div v-if="blog" v-html="blog.body" class="blog-content"></div>
          <div class="blog-content" v-else>
            <b-skeleton
              v-for="i in 10"
              :key="i"
              width="100%"
              class="mb-2"
            ></b-skeleton>
          </div>
        </section>
        <section
          class=" weblog-shared border-radius15 bg-white box-shaddow20 p-3 mb-4 d-flex flex-wrap justify-content-between"
        >
          <span class="ml-2 text-color-444 fontsize13 font-weight-bold">
            اشتراک گذاری مطلب :</span
          >
          <div class="">
            <a
              id="blog-social-whatsapp"
              :href="
                'whatsapp://send?text=' + encodeURIComponent(getBlogLink())
              "
              data-action="share/whatsapp/share"
              class="mx-2"
            >
              <svg
                width="17"
                height="15"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <path
                  d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                        C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                        c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                        c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                        c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                        c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                        c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                        c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                        c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"
                  fill="#999"
                />
              </svg>
            </a>
            <a v-if="false" id="blog-social-instagram" href="" class="mx-2">
              <svg
                width="15"
                height="15"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <path
                  d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
                                        C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
                                        h192c61.76,0,112,50.24,112,112V352z"
                  fill="#999"
                />
                <path
                  d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
                                        c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"
                  fill="#999"
                />
                <circle cx="393.6" cy="118.4" r="17.056" fill="#999" />
              </svg>
            </a>
            <div
              id="blog-social-twitter"
              :href="
                'http://twitter.com/share?url=' +
                  encodeURIComponent(getBlogLink())
              "
              class="mx-2"
            >
              <svg
                width="15"
                height="15"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <path
                  d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                                        c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                                        c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                                        c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                                        c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                                        c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                                        C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                                        C480.224,136.96,497.728,118.496,512,97.248z"
                  fill="#999"
                />
              </svg>
            </div>
            <div v-if="false" id="blog-social-skype" class="mx-2">
              <svg
                width="15"
                height="15"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <path
                  d="M497.28,310.336c3.712-16.672,5.632-33.952,5.632-51.68c0-132.96-109.312-240.768-244.192-240.768
                                        c-14.208,0-28.16,1.216-41.76,3.52C195.136,7.872,169.312,0,141.632,0C63.392,0,0,62.496,0,139.648
                                        c0,25.76,7.104,49.856,19.456,70.624c-3.232,15.616-4.96,31.808-4.96,48.384c0,132.992,109.344,240.8,244.192,240.8
                                        c15.296,0,30.24-1.408,44.736-4.032C323.392,505.984,346.176,512,370.4,512c78.208,0,141.6-62.528,141.6-139.616
                                        C512,350.08,506.688,329.056,497.28,310.336z M383.968,373.728c-11.296,15.776-27.968,28.256-49.632,37.088
                                        c-21.408,8.8-47.04,13.28-76.288,13.28c-35.072,0-64.48-6.08-87.456-18.112c-16.416-8.736-29.92-20.544-40.192-35.2
                                        c-10.4-14.72-15.616-29.344-15.616-43.488c0-8.8,3.424-16.448,10.176-22.688c6.688-6.24,15.264-9.344,25.504-9.344
                                        c8.384,0,15.616,2.464,21.504,7.36c5.6,4.704,10.432,11.68,14.304,20.608c4.32,9.792,9.024,18.048,13.984,24.48
                                        c4.832,6.304,11.712,11.552,20.512,15.68c8.864,4.096,20.8,6.24,35.456,6.24c20.192,0,36.768-4.256,49.184-12.64
                                        c12.192-8.16,18.08-18.016,18.08-30.048c0-9.472-3.104-16.96-9.408-22.816c-6.656-6.144-15.424-10.912-26.048-14.208
                                        c-11.104-3.392-26.176-7.104-44.8-10.944c-25.376-5.344-46.912-11.68-64-18.88c-17.504-7.36-31.648-17.536-41.952-30.272
                                        c-10.496-12.96-15.808-29.184-15.808-48.256c0-18.176,5.568-34.56,16.576-48.704c10.912-14.048,26.848-25.024,47.424-32.48
                                        c20.256-7.392,44.352-11.136,71.648-11.136c21.792,0,40.96,2.496,56.992,7.424c16.096,4.928,29.664,11.584,40.32,19.808
                                        c10.752,8.32,18.752,17.12,23.744,26.336c5.056,9.28,7.648,18.528,7.648,27.456c0,8.608-3.36,16.448-10.016,23.232
                                        c-6.72,6.88-15.168,10.336-25.088,10.336c-9.024,0-16.128-2.208-21.024-6.464c-4.576-4-9.344-10.24-14.592-19.136
                                        c-6.08-11.392-13.472-20.384-21.92-26.752c-8.224-6.176-21.92-9.248-40.8-9.248c-17.472,0-31.744,3.456-42.304,10.304
                                        c-10.176,6.56-15.136,14.112-15.136,23.072c0,5.472,1.6,10.048,4.896,13.984c3.456,4.224,8.352,7.84,14.56,10.912
                                        c6.4,3.168,13.024,5.728,19.648,7.52c6.784,1.856,18.144,4.64,33.792,8.192c19.776,4.16,37.92,8.864,53.984,13.952
                                        c16.288,5.088,30.304,11.392,41.824,18.784c11.68,7.52,20.928,17.12,27.52,28.64c6.592,11.616,9.92,25.856,9.92,42.432
                                        C401.056,339.84,395.296,357.92,383.968,373.728z"
                  fill="#999"
                />
              </svg>
            </div>
            <a
              id="blog-social-facebook"
              :href="
                'https://www.facebook.com/sharer/sharer.php?u=' +
                  encodeURIComponent(getBlogLink())
              "
              class="mx-2"
            >
              <svg
                width="15"
                height="15"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <path
                  d="M448,0H64C28.704,0,0,28.704,0,64v384c0,35.296,28.704,64,64,64h192V336h-64v-80h64v-64c0-53.024,42.976-96,96-96h64v80
                                        h-32c-17.664,0-32-1.664-32,16v64h80l-32,80h-48v176h96c35.296,0,64-28.704,64-64V64C512,28.704,483.296,0,448,0z"
                  fill="#999"
                />
              </svg>
            </a>
          </div>
        </section>
        <section v-if="relatedBlogs.length" class="same-content-main container">
          <div class=" mb-3">
            <h5 class="d-inline-block">
              <span class="weight-bold text-color-444">مطالب</span>
              <span class="text-color-themeBlue fontsize14 font-weight-bolder">
                مشابه</span
              >
            </h5>
          </div>
          <div
            v-if="!disabledSliders.includes('sameBlogs')"
            class="swiper-container py-3"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(relatedBlog, index) in relatedBlogs"
                :key="index"
              >
                <Blog :item="relatedBlog"></Blog>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </section>
        <BlogComments v-model="blog"></BlogComments>
      </div>
    </div>
  </main>
</template>
<script>
import Swiper from "swiper/swiper-bundle.js";
import "swiper/swiper-bundle.css";
import Blog from "./component/blog/blog";
import CategoryList from "./component/blog/categoryList";
import PopularBlogs from "./component/blog/popularBlogs";
import BlogComments from "./component/blog/blogComments";

export default {
  components: { BlogComments, PopularBlogs, Blog, CategoryList },
  data() {
    return {
      id: null,
      blog: null,
      relatedBlogs: Array(6).fill(null),
    };
  },
  mounted() {
    this.initBlogs();
    this.id = this.$route.params.id;
    this.loadBlog();
  },
  methods: {
    loadBlog() {
      this.$axios
        .get("/api/blogs/" + this.id)
        .then((response) => {
          const data = response.data.data;
          this.blog = data.blog;
          this.relatedBlogs = data.blog.similar_blogs;
          this.initBlogs(true);
        })
        .catch(function(error) {
          this.$root.error_notification(error);
        })
        .finally(() => {});
    },
    initBlogs(kill = false) {
      this.initSwiper("sameBlogs", kill, () => {
        new Swiper(".same-content-main .swiper-container", {
          spaceBetween: 30,
          // centeredSlides: true,
          loop: false,
          speed: 700,
          // autoplay: {
          //   delay: 3000,
          //   disableOnInteraction: false,
          // },
          pagination: {
            el: ".same-content-main .swiper-pagination",
            clickable: true,
          },

          breakpoints: {
            500: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          },
        });
      });
    },
    getBlogLink() {
      // گرفتن لینک این بلاگ برای اشتراک گزاری
      if (!this.blog) {
        return "";
      }
      return (
        window.location.hostname +
        "/blogs/" +
        this.blog.id +
        "/" +
        this.blog.slug
      );
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content: !this.blog ? "بلاگ" : this.blog.lead,
        },
        {
          property: "og:title",
          content: !this.blog ? "بلاگ" : this.blog.title,
        },
        { property: "og:site_name", content: "alba market" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>
<style>
.blog-content {
  margin-top: 5px;
}
</style>
<style scoped>
.loading-blog-image {
  height: 300px;
}

@media screen and (max-width: 500px) {
  .loading-blog-image {
    height: 185px;
  }
}
@media screen and (max-width: 600px) and (min-width: 501px) {
  .loading-blog-image {
    height: 195px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
  .loading-blog-image {
    height: 230px;
  }
}
</style>
