<template>
  <section class="weblog-comments-section border-radius15 bg-white box-shaddow20 p-3 mt-4">
    <div class="w-100">
      <h6 class="text-color-444">پاسخی بگذارید</h6>
      <p class="d-inline-block text-color-444 fontsize11">نشانی ایمیل شما منتشر نخواهد شد . بخش های
        مورد نیاز علامت گذاری شده اند با<span class="text-danger">*</span></p>

      <BlogCommentForm :submitComment="submitComment" :sendingRequest="sendingRequest"></BlogCommentForm>
    </div>


    <div v-if="value && value.comments" class="weblog-comments" id="legal-questions">
      <transition-group name="fade">
        <div v-for="comment in value.comments" :key="comment.id" class=" pb-3">
          <div class="d-flex py-3 px-sm-4 pl-4 pr-1 user-question">
            <div class="question-tab-content-img ml-3">
              <svg width="17" height="17" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
                      fill="#fff"/>
              </svg>
            </div>
            <div class="question-tab-content-text">
              <div class="d-flex flex-wrap">
                <p class="fontsize13 weight-bold text-dark mb-0">{{ comment.name }}</p>
                <p class="fontsize11 text-secondary mr-2 mt-1">{{ moment(comment.created_at).format('jYYYY-jMM-jDD') }}</p>
                <p class="fontsize11 text-warning mr-2 mt-1" v-if="comment.status == 0">نظر شما پس از تایید نمایش داده خواهد شد</p>
              </div>

              <p class="user-question-text fontsize12  text-dark text-justify">{{ comment.comment }}</p>
                <button v-if="comment.status != 0" @click="answerTo(comment)" class="text-color-themeBlue fontsize13 weight-bold">
                  پاسخ دهید
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                       fill="currentColor" class="bi bi-reply-fill" viewBox="0 0 16 16">
                    <path
                        d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z"
                        fill="#7570b3"/>
                  </svg>
                </button>
              <transition name="fast-fade">
              <div class="mt-2" v-if="answeringComment && answeringComment.id == comment.id">
                <BlogCommentForm :replying-to="comment"
                                 :submitComment="submitComment" :sendingRequest="sendingRequest"></BlogCommentForm>
              </div>
              </transition>
            </div>
          </div>
          <template v-if="comment.children && comment.children.length">
            <div v-for="childComment in comment.children" :key="childComment.id" class="d-flex py-3 px-sm-5 pl-4 pr-1 admin-answer">
              <div class="answer-tab-content-img ml-3">
                <svg width="17" height="17" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
                        fill="#fff"/>
                </svg>
              </div>
              <div class="question-tab-content-text">
                <div class="d-flex flex-wrap">
                  <p class="fontsize13 weight-bold text-dark mb-0">{{ childComment.name }}  پاسخ به {{ comment.name }}</p>
                  <p class="fontsize11 text-secondary mr-2 mt-1">{{ moment(childComment.created_at).format('jYYYY-jMM-jDD HH:MM') }}</p>
                  <p class="fontsize11 text-warning mr-2 mt-1" v-if="childComment.status == 0">نظر شما پس از تایید نمایش داده خواهد شد</p>
                </div>
                <p class="user-question-text fontsize12  text-dark text-justify">{{ childComment.comment }}</p>
              </div>
            </div>
          </template>
        </div>
      </transition-group>

    </div>
  </section>

</template>

<script>
import BlogCommentForm from './blogCommentForm';

export default {
  name: "blogComments",
  components: {BlogCommentForm},
  data() {
      return {
        sendingRequest: false,
        answeringComment: null,
      }
  },
  props: {
    value: Object
  },
  methods: {
    submitComment(e, replyingTo) {
      this.sendingRequest = true;
      let formData = new FormData(e.target)
      if (replyingTo) {
          formData.append('parent_id', replyingTo.id)
      }
      this.$axios.post(`/api/blogs/${this.value.id}/comment`, formData)
          .then((response) => {
            this.$root.success_notification(response.data.message)
            if (replyingTo) {
              let replyingToComment = null;
              this.value.comments.forEach(item => {
                if (item.id == replyingTo.id) {
                  replyingToComment = item;
                }
              })
              if (replyingToComment) {
                replyingToComment.children.unshift(response.data.data)
              }
              this.answeringComment = null
            } else {
              this.value.comments.unshift(response.data.data)
            }
            this.resetForm();
          })
          .catch(error => {
            console.log(error)
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.sendingRequest = false
          });
    },
    resetForm() {
      this.$('form#send-comment-form input, form#send-comment-form textarea').val('');

    },
    updateValue() {
      this.$emit('input', this.value)
    },
    answerTo(comment) {
      if (this.answeringComment && comment.id == this.answeringComment.id) {
        this.answeringComment = null
      } else {
        this.answeringComment = comment
      }
    }
  },

}
</script>
<style scoped>

</style>